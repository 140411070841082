import React from "react";
import styled from "styled-components";
import HeaderCatalogo from "../components/Catalogo/HeaderCatalogo";
import HeroCatalogo from "../components/Catalogo/HeroCatalogo";
import BodyCatalogo from "../components/Catalogo/BodyCatalogo";
import FooterCatalogo from "../components/Catalogo/FooterCatalogo";

const CatalogoContainer = styled.div`
  margin: 0 auto;
  max-width: 1280px;
`;

function Catalogo({ formInfo }) {
  return (
    <CatalogoContainer>
      <HeaderCatalogo />
      <HeroCatalogo formInfo={formInfo} />
      <BodyCatalogo formInfo={formInfo} />
      <FooterCatalogo />
    </CatalogoContainer>
  );
}

export default Catalogo;
