import React, { useContext } from "react";
import { Drawer } from "antd";
import Catalogo from "../../pages/Catalogo";
import styled from "styled-components";
import { CloseOutlined } from "@ant-design/icons";
import { PrevPlatformContext, Text } from "@prev/ui-kit";

const StyledDrawer = styled(Drawer)`
  && .ant-drawer-header {
    padding: 0;
    position: absolute;
    background: transparent;
    top: 90px;
    z-index: 3;
  }

  && .anticon-close > svg {
    font-size: 13px;
    color: ${(props) => props.theme.color.brand.secondary};
  }

  && .ant-drawer-close {
    padding: 0;
    margin: 0;
  }

  && .ant-drawer-body {
    padding: 0;
  }

  && .ant-drawer-content-wrapper {
    width: 80%;
    max-width: 1280px;
  }
`;

const StyledExitButton = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgb(255, 255, 255);
  border-radius: 50%;
  box-shadow: rgba(0, 0, 0, 0.17) 0px 10px 30px 0px;
  cursor: pointer;
  width: 50px;
  height: 50px;
  margin-left: -70px;
  position: fixed;
`;

function DrawerOpen(props) {
  const { formInfo, setShowDrawer, showDrawer } = props;
  const { theme } = useContext(PrevPlatformContext);
  return (
    <StyledDrawer
      style={{ padding: 0 }}
      placement="right"
      width={"80%"}
      closable={true}
      visible={showDrawer}
      onClose={() => setShowDrawer(false)}
      bodyStyle={{ backgroundColor: theme.color.brand.secondary }}
      closeIcon={
        <StyledExitButton
          aria-label="Fechar pré visualização"
          id="btn-fechar-pre-visualizacao"
          data-testid="btn-fechar-pre-visualizacao"
        >
          <CloseOutlined />
          <Text
            style={{ marginLeft: 2 }}
            type="span"
            emphasys
            size="xs"
            color={theme.color.brand.secondary}
          >
            FECHAR
          </Text>
        </StyledExitButton>
      }
    >
      <Catalogo formInfo={formInfo} />
    </StyledDrawer>
  );
}

export default DrawerOpen;
