import axios from "axios";
import { API_HOST } from "../core/Constants";
import { getToken } from "./getToken";

export const withToken = (fn) => async (params) => {
  if (!getToken()) return;
  const token = getToken();
  return await fn({ token, ...params });
};

export const authenticatedGet = withToken(
  async ({ path, token, host = API_HOST, headers = {}, ...rest }) => {
    const url = `${host}${path}`;
    const auth = "Bearer " + token;

    return await axios({
      url,
      method: "GET",
      headers: { Authorization: auth, ...headers },
      ...rest,
    })
      .then((response) => {
        if (response.data) {
          return response.data;
        }
        if (response.status === 204) {
          if (path !== "/pagamentos") {
            return [];
          } else {
            return null;
          }
        }
      })
      .catch((e) => {
        if (path !== "/pagamentos") {
          return handleErrors(e);
        } else {
          return null;
        }
      });
  }
);

export const authenticatedPut = withToken(
  async ({ path, data, token, host = API_HOST, headers = {} }) => {
    const url = `${host}${path}`;
    const auth = "Bearer " + token;

    return await axios
      .put(url, data, {
        headers: {
          Authorization: auth,
          ...headers,
        },
      })
      .then(function (response) {
        if (response.data) {
          return response.data;
        }
        if (response.status === 204) {
          return [];
        }
      })
      .catch(handleErrors);
  }
);

export const authenticatedPatch = withToken(
  async ({ path, data, token, host = API_HOST }) => {
    const url = `${host}${path}`;
    const auth = "Bearer " + token;

    return await axios
      .patch(url, data, {
        headers: { Authorization: auth },
      })
      .then(function (response) {
        if (response.data) {
          return response.data;
        }
        if (response.status === 204) {
          return [];
        }
      })
      .catch(handleErrors);
  }
);

export const authenticatedDelete = withToken(
  async ({ path, token, host = API_HOST }) => {
    const url = `${host}${path}`;
    const auth = "Bearer " + token;

    return await axios
      .delete(url, {
        headers: { Authorization: auth },
      })
      .then(function (response) {
        if (response.data) {
          return response.data;
        }
        if (response.status === 204) {
          return {};
        }
      })
      .catch(handleErrors);
  }
);

const handleErrors = (error) => {
  const { response } = error || {};

  if (window._trackJs && window.trackJs) {
    if (response && response.config && response.config.data) {
      try {
        let obj = JSON.parse(response.config.data);
        if (obj.numero) {
          delete obj.numero;
        }
        if (obj.cvv) {
          delete obj.cvv;
        }

        window.trackJs.console.log(obj);
      } catch (e) {}
    }
  }

  throw error;
};
