import { PrevPlatformProvider, SegmentProvider } from "@prev/ui-kit";
import React, { useEffect } from "react";
import UserInfosProvider from "./context/UserInfosProvider";
import MeuEscritorio from "./pages/MeuEscritorio";

const getContainer = (props) => {
  if (localStorage.getItem("access_token_prev")) {
    return <MeuEscritorio {...props} />;
  } else {
    window.location.href = "/";
  }
};

export default function Root(props) {
  useEffect(() => {
    document.getElementsByTagName('body')[0].style.backgroundColor = 'white'
  }, [])

  return (
    <PrevPlatformProvider>
      <UserInfosProvider>
        <SegmentProvider>{getContainer(props)}</SegmentProvider>
      </UserInfosProvider>
    </PrevPlatformProvider>
  );
}
