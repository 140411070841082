import React from "react";
import { Text, Card } from "@prev/ui-kit";
import infoPrevImage from "../images/prevInfoImage.png";

function onTabClickFunction() {
  const editarDadosTab = document.querySelector("#tabs-selecao-tab-3");
  document.body.scrollTop = document.documentElement.scrollTop = 0;
  editarDadosTab.click();
}

function InfoCard() {
  return (
    <Card
      style={{
        padding: "16px",
        margin: "2px",
      }}
    >
      <img width={"100%"} alt="prevInfoImage" src={infoPrevImage} />
      <Text type="paragraph">
        Quanto mais dados você colocar, maior será o alcance do seu escritório.
      </Text>
      <Text type="paragraph">
        Clique em{" "}
        <Text type="span">
          <Text
            type="span"
            color="#3575AB"
            style={{ textDecoration: "underline" }}
            onClick={onTabClickFunction}
            onMouseOver={({ target }) => {
              target.style.cursor = "pointer";
            }}
          >
            editar dados
          </Text>
        </Text>{" "}
        e garanta que todos os espaços estejam preenchidos.
      </Text>
    </Card>
  );
}

export default InfoCard;
