import React from "react";
import moment from "moment";
import { Popover } from "antd";
import { Text } from "@prev/ui-kit";

export const API_HOST = `${process.env.REACT_APP_PREV_API}`;
export const API_HOST_DASHBOARD_V2 = `${API_HOST}/dashboard/v2`;
export const MAPBOX_TOKEN = `${process.env.REACT_APP_MAPBOX_ACCESS_TOKEN}`;

export const FILTROS_DATERANGE = [
  {
    title: <Popover content={<Text>últimos 7 dias</Text>}>7D</Popover>,
    params: {
      inicio: moment().add(-6, `days`).format("YYYY-MM-DD HH:mm:ss"),
      fim: moment().format("YYYY-MM-DD HH:mm:ss"),
      agrupadoPor: "DIA",
    },
  },
  {
    title: <Popover content={<Text>últimos 30 dias</Text>}>30D</Popover>,
    params: {
      inicio: moment().add(-31, `days`).format("YYYY-MM-DD HH:mm:ss"),
      fim: moment().format("YYYY-MM-DD HH:mm:ss"),
      agrupadoPor: "DIA",
    },
  },
  {
    title: (
      <Popover content={<Text>último mês, do dia 1 ao último dia</Text>}>
        1M
      </Popover>
    ),
    params: {
      inicio: moment()
        .add(-1, `month`)
        .startOf(`month`)
        .format("YYYY-MM-DD HH:mm:ss"),
      fim: moment()
        .add(-1, `month`)
        .endOf(`month`)
        .format("YYYY-MM-DD HH:mm:ss"),
      agrupadoPor: "DIA",
    },
  },
  {
    title: (
      <Popover content={<Text>último ano, do dia 1 ao último dia</Text>}>
        1A
      </Popover>
    ),
    params: {
      inicio: moment()
        .add(-1, `year`)
        .startOf(`year`)
        .format("YYYY-MM-DD HH:mm:ss"),
      fim: moment().add(-1, `year`).endOf(`year`).format("YYYY-MM-DD HH:mm:ss"),
      agrupadoPor: "MES",
    },
  },
  {
    title: (
      <Popover content={<Text>mês atual, do dia 1 ao dia atual</Text>}>
        MTD
      </Popover>
    ),
    params: {
      inicio: moment().startOf(`month`).format("YYYY-MM-DD HH:mm:ss"),
      fim: moment().format("YYYY-MM-DD HH:mm:ss"),
      agrupadoPor: "DIA",
    },
  },
  {
    title: (
      <Popover content={<Text>ano atual, do dia 1 ao dia atual</Text>}>
        YTD
      </Popover>
    ),
    params: {
      inicio: moment().startOf(`year`).format("YYYY-MM-DD HH:mm:ss"),
      fim: moment().format("YYYY-MM-DD HH:mm:ss"),
      agrupadoPor: "MES",
    },
  },
  {
    title: (
      <Popover content={<Text>Intervalo personalizado</Text>}>
        Personalizado
      </Popover>
    ),
    params: {
      inicio: moment().startOf(`year`).format("YYYY-MM-DD HH:mm:ss"),
      fim: moment().format("YYYY-MM-DD HH:mm:ss"),
      agrupadoPor: "MES",
    },
    disabled: true,
  },
];

export const MESES = [
  "JAN",
  "FEV",
  "MAR",
  "ABR",
  "MAI",
  "JUN",
  "JUL",
  "AGO",
  "SET",
  "OUT",
  "NOV",
  "DEZ",
];
