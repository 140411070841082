import React from "react";
import styled from "styled-components";
import { Row, Col } from "antd";
import prevSemFundo from "../../images/prevSemFundo.png";
import { Button, Text } from "@prev/ui-kit";

const FooterContainer = styled.div`
  height: max-content;
  width: 100%;
  background-color: #485158;
  padding-top: 50px;
`;

const GapDivisors = styled.div`
  display: flex;
  flex-direction: column;
  gap: 18px;
  margin-left: 20px;
`;

function FooterCatalogo() {
  return (
    <FooterContainer>
      <Row>
        <Col sm={24} md={4}>
          <div>
            <img
              style={{ marginLeft: 20 }}
              width="80px"
              src={prevSemFundo}
              alt="logo previdenciarista"
            />
          </div>
        </Col>
        <Col sm={24} md={20}>
          <Row gutter>
            <Col xs={24} md={6}>
              <GapDivisors>
                <Text size="xl" emphasys color="white">
                  Advogados
                </Text>
                <Text color="#ffffffb3">Buscar petições previdenriárias</Text>
                <Text color="#ffffffb3">Planos de assinatura</Text>
                <Text color="#ffffffb3">Benefícios previdenciários</Text>
              </GapDivisors>
            </Col>
            <Col xs={24} md={6}>
              <GapDivisors>
                <Text size="xl" emphasys color="white">
                  Produto
                </Text>
                <Text color="#ffffffb3">Cálculos previdenciários</Text>
                <Text color="#ffffffb3">Liquidação de Sentença</Text>
                <Text color="#ffffffb3">Revisão da Vida toda</Text>
                <Text color="#ffffffb3">Petições</Text>
                <Text color="#ffffffb3">Clientes</Text>
                <Text color="#ffffffb3">Escritório</Text>
              </GapDivisors>
            </Col>
            <Col xs={24} md={6}>
              <GapDivisors>
                <Text size="xl" emphasys color="white">
                  Petições
                </Text>
                <Text color="#ffffffb3">Petições previdenciárias</Text>
                <Text color="#ffffffb3">Petições iniciais</Text>
                <Text color="#ffffffb3">Recursos</Text>
                <Text color="#ffffffb3">Contrarazões</Text>
                <Text color="#ffffffb3">Requerimentos previdenciaristas</Text>
              </GapDivisors>
            </Col>
            <Col xs={24} md={6}>
              <GapDivisors>
                <Text size="xl" emphasys color="white">
                  Institucional
                </Text>
                <Text color="#ffffffb3">História</Text>
                <Text color="#ffffffb3">Quem somos</Text>
                <Text color="#ffffffb3">Equipe</Text>
                <Text color="#ffffffb3">Ajuda</Text>
                <Text color="#ffffffb3">Siga-nos no Facebook</Text>
              </GapDivisors>
            </Col>
          </Row>
        </Col>
        <Col style={{ padding: "0px 20px" }} span={24}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              flexWrap: "wrap",
            }}
          >
            <div>
              <Button textColor="white" variant="link">
                Termos de uso
              </Button>
              <Button textColor="white" variant="link">
                Política de Privacidade
              </Button>
            </div>
            <Text style={{ margin: "30px 10px" }} emphasys color="white">
              Previdenciarista - CNPJ 19.765.871/0001-24
            </Text>
          </div>
        </Col>
      </Row>
    </FooterContainer>
  );
}

export default FooterCatalogo;
