import React, { useContext, useState } from "react";
import styled from "styled-components";
import { Row, Col, Form } from "antd";
import {
  Button,
  Card,
  Checkbox,
  FieldInput,
  PrevPlatformContext,
  Text,
  TextArea,
} from "@prev/ui-kit";
import { ReconciliationOutlined, EnvironmentOutlined } from "@ant-design/icons";
import { UserInfosContext } from "../../context/UserInfosProvider";
import defaultProfileImage from "../../images/defaultProfileImg.png";

const BodyContainer = styled.div`
  width: 100%;
  height: max-content;
  background-color: #e5e5e5;
  padding: 24px 16px 50px 16px;
`;

const AtuacaoContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
`;

const CustomCard = styled(Card)`
  padding: 16px;
  margin: 0;
`;

const ContainerForMap = styled.div`
  display: flex;
  align-items: center;
  min-height: 24px;
  margin-right: 34px;
  min-width: 270px;
`;

const TELEFONE_FAKE = "(99) 99999-9999";

function BodyCatalogo({ formInfo }) {
  const { theme } = useContext(PrevPlatformContext);
  const { userInfos } = useContext(UserInfosContext);
  const {
    cidadesDeAtuacao = [],
    especialidades = [],
    telefone = "",
    telefoneSecundario = "",
    descricao = "",
  } = formInfo;

  const [showTelephone, setShowTelephone] = useState(false);
  return (
    <BodyContainer>
      <Row gutter={[16, 16]}>
        <Col xs={24} sm={24} lg={16}>
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <CustomCard style={{ minHeight: 176 }}>
                <Text
                  style={{ marginBottom: 8 }}
                  size="lg"
                  emphasys
                  color={theme.color.brand.secondary}
                >
                  Sobre
                </Text>
                <Text style={{ whiteSpace: "pre-line" }}>{descricao}</Text>
              </CustomCard>
            </Col>

            <Col span={24}>
              <CustomCard style={{ minHeight: 128 }}>
                <Text
                  style={{ marginBottom: 16 }}
                  size="lg"
                  emphasys
                  color={theme.color.brand.secondary}
                >
                  Áreas de atuação
                </Text>
                <AtuacaoContainer>
                  {especialidades.map((atuacao) => (
                    <ContainerForMap key={atuacao.id}>
                      <ReconciliationOutlined
                        style={{
                          marginRight: 8,
                          color: theme.color.brand.secondary,
                          whiteSpace: "pre-line",
                        }}
                      />
                      <Text color={theme.color.neutral[600]}>
                        {atuacao.label}
                      </Text>
                    </ContainerForMap>
                  ))}
                </AtuacaoContainer>
              </CustomCard>
            </Col>

            <Col span={24}>
              <CustomCard style={{ minHeight: 96 }}>
                <Text
                  style={{ marginBottom: 16 }}
                  size="lg"
                  emphasys
                  color={theme.color.brand.secondary}
                >
                  Cidades de atuação
                </Text>
                <AtuacaoContainer>
                  {cidadesDeAtuacao.map((cidade) => (
                    <ContainerForMap key={cidade.id}>
                      <EnvironmentOutlined
                        style={{
                          marginRight: 8,
                          color: theme.color.brand.secondary,
                        }}
                      />
                      <Text color={theme.color.neutral[600]}>
                        {cidade.label}
                      </Text>
                    </ContainerForMap>
                  ))}
                </AtuacaoContainer>
              </CustomCard>
            </Col>

            <Col span={24}>
              <CustomCard style={{ minHeight: 112 }}>
                <Text
                  style={{ marginBottom: 16 }}
                  size="lg"
                  emphasys
                  color={theme.color.brand.secondary}
                >
                  Equipe
                </Text>
                <AtuacaoContainer>
                  <Row>
                    {userInfos.escritorio.membros.map((advogado, index) => (
                      <Col key={index}>
                        <ContainerForMap>
                          <img
                            style={{
                              width: 40,
                              borderRadius: "50%",
                              marginRight: 8,
                              height: 40,
                            }}
                            src={
                              advogado.urlImagemPerfil
                                ? advogado.urlImagemPerfil
                                : defaultProfileImage
                            }
                            alt={`foto advogado ${advogado.nome}`}
                            onError={(event) => {
                              event.target.src = defaultProfileImage;
                              event.onerror = null;
                            }}
                          />
                          <div>
                            <Text color={theme.color.brand.secondary}>
                              {advogado.nome}
                            </Text>
                            <Text size="sm" color={theme.color.neutral[600]}>
                              {advogado.oab}
                            </Text>
                          </div>
                        </ContainerForMap>
                      </Col>
                    ))}
                  </Row>
                </AtuacaoContainer>
              </CustomCard>
            </Col>
            <Col span={24}>
              <CustomCard
                style={{
                  backgroundColor: theme.color.brand.secondary,
                }}
              >
                <Row align="middle" gutter={[16, 16]}>
                  <Col xs={24} lg={14}>
                    <Text color="white">
                      Você é um{" "}
                      <Text type="span" emphasys color="white">
                        advogado
                      </Text>
                      ?
                    </Text>
                    <Text color="white">
                      Faça como mais de{" "}
                      <Text color="white" type="span" emphasys>
                        9.000
                      </Text>{" "}
                      profissionais e assine o{" "}
                      <Text color="white" type="span" emphasys>
                        Prev.
                      </Text>
                    </Text>
                    <Text color="white">
                      Acesse petições e{" "}
                      <Text color="white" type="span" emphasys>
                        faça
                      </Text>{" "}
                      quantos{" "}
                      <Text color="white" type="span" emphasys>
                        cálculos quiser!
                      </Text>
                    </Text>
                  </Col>
                  <Col xs={24} lg={10}>
                    <Button
                      id="btn-teste-gratis"
                      data-testeid="btn-teste-gratis"
                      aria-label="Teste Grátis por 15 dias"
                      style={{ width: "100%", height: 48 }}
                      color="white"
                    >
                      <Text emphasys color={theme.color.brand.secondary}>
                        Teste Grátis por 15 dias
                      </Text>
                    </Button>
                  </Col>
                </Row>
              </CustomCard>
            </Col>
          </Row>
        </Col>
        <Col xs={24} sm={24} lg={8}>
          <CustomCard style={{ height: "100%" }}>
            <Text
              style={{ marginBottom: 16 }}
              size="lg"
              emphasys
              color={theme.color.brand.secondary}
            >
              Contato
            </Text>

            <Text
              style={{ marginBottom: 6 }}
              emphasys
              color={theme.color.neutral[1000]}
            >
              Telefone
            </Text>

            <div
              style={{
                height: 25,
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: 21,
              }}
            >
              {showTelephone ? (
                <Text>
                  {telefone}
                  {telefoneSecundario ? (
                    <>
                      <br />
                      {telefoneSecundario}
                    </>
                  ) : null}
                </Text>
              ) : (
                <Text style={{ filter: "blur(3px)" }}>{TELEFONE_FAKE}</Text>
              )}
              <Button
                onClick={() => setShowTelephone(!showTelephone)}
                size="small"
              >
                Ver telefone
              </Button>
            </div>

            <Text
              style={{ marginBottom: 12 }}
              emphasys
              color={theme.color.neutral[1000]}
            >
              Enviar mensagem
            </Text>

            <Form layout="vertical">
              <Row>
                <Col span={24}>
                  <Form.Item
                    name="nomeCompleto"
                    rules={[{ required: true }, { type: "string", min: 6 }]}
                    style={{ marginBottom: 18 }}
                  >
                    <FieldInput
                      required
                      disabled
                      label="Nome Completo"
                      placeholder="Digite seu nome"
                    />
                  </Form.Item>
                </Col>

                <Col span={24}>
                  <Form.Item
                    name="email"
                    rules={[{ required: true }, { type: "email", min: 6 }]}
                    style={{ marginBottom: 18 }}
                  >
                    <FieldInput
                      required
                      disabled
                      label="Email"
                      placeholder="exemplo@exemplo.com.br"
                    />
                  </Form.Item>
                </Col>

                <Col span={24}>
                  <Form.Item
                    name="telefone"
                    rules={[{ required: true }, { type: "string", min: 6 }]}
                    style={{ marginBottom: 18 }}
                  >
                    <FieldInput
                      required
                      disabled
                      label="Telefone"
                      placeholder="(00) - 000000 - 0000"
                    />
                  </Form.Item>
                </Col>

                <Col span={24}>
                  <Form.Item
                    name="mensagem"
                    rules={[{ required: true }, { type: "string", min: 6 }]}
                    style={{ marginBottom: 22 }}
                  >
                    <TextArea
                      disabled
                      required
                      size="large"
                      placeholder="Mensagem"
                    />
                  </Form.Item>
                </Col>

                <Col span={24}>
                  <Form.Item name="checkboxEmail" style={{ marginBottom: 22 }}>
                    <div style={{ display: "flex" }}>
                      <Checkbox disabled />
                      <Text>
                        Quero receber contatos do{" "}
                        <Text type="span" emphasys>
                          Prev
                        </Text>{" "}
                        por email sobre soluções na aposentadoria.
                      </Text>
                    </div>
                  </Form.Item>
                </Col>

                <Col span={24}>
                  <Button disabled style={{ width: "100%", height: 40 }}>
                    Enviar Mensagem
                  </Button>
                </Col>
              </Row>
            </Form>
          </CustomCard>
        </Col>
      </Row>
    </BodyContainer>
  );
}

export default BodyCatalogo;
