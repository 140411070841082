import React from "react";
import { Row, Col } from "antd";
import { Text, Card } from "@prev/ui-kit";
import { EyeOutlined, EyeInvisibleOutlined } from "@ant-design/icons";

function riskedEye(mode, setClick, whichIcon, handleClick) {
  setClick((old) => {
    return { ...old, [whichIcon]: !old[whichIcon] };
  });
  handleClick(whichIcon, mode);
}

function Modulescard({
  Icon,
  titleCard,
  amount,
  dateRange,
  textColor,
  iconBackgroundColor,
  click,
  setClick,
  whichIcon,
  handleClick,
  testid,
  ...props
}) {
  return (
    <Card
      {...props}
      style={{
        padding: `8px 16px`,
        margin: `0px 4px`,
      }}
    >
      <Row>
        <Col span={20}>
          <Row>
            <Col span={24}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  minHeight: "100%",
                }}
              >
                <Icon
                  rotate={props.rotate}
                  style={{
                    margin: 0,
                    marginRight: "8px",
                    color: click[whichIcon] ? "#DDDDDC" : textColor,
                    backgroundColor: iconBackgroundColor,
                    borderRadius: "4px",
                    fontSize: "14px",
                    padding: "5px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                />
                <Text
                  color={click[whichIcon] ? "#DDDDDC" : "#242525"}
                  type="span"
                  emphasys
                >
                  {titleCard}
                </Text>
              </div>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Text
                style={{ marginRight: "8px" }}
                type="span"
                color={click[whichIcon] ? "#DDDDDC" : textColor}
                size="xl-2"
                emphasys
                data-testid={`total_${testid}`}
              >
                {amount}
              </Text>
              <Text
                type="span"
                color={click[whichIcon] ? "#DDDDDC" : "#949595"}
                size="sm"
              >
                {dateRange}
              </Text>
            </Col>
          </Row>
        </Col>
        <Col span={4}>
          <div
            style={{
              minHeight: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {click[whichIcon] ? (
              <EyeInvisibleOutlined
                onClick={() =>
                  riskedEye("add", setClick, whichIcon, handleClick)
                }
                style={{ color: "#F26526", fontSize: "16px" }}
              />
            ) : (
              <EyeOutlined
                onClick={() =>
                  riskedEye("delete", setClick, whichIcon, handleClick)
                }
                style={{ color: "#F26526", fontSize: "16px" }}
              />
            )}
          </div>
        </Col>
      </Row>
    </Card>
  );
}

export default Modulescard;
