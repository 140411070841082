import { Button } from "@prev/ui-kit";
import React from "react";
import styled from "styled-components";
import prevIcon from "../images/prevIcon.png";
import { ArrowLeftOutlined } from "@ant-design/icons";

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px;
`;

const BackButton = styled(Button)`
  &&.ant-btn.ant-btn-link {
    font-weight: 600;
    padding-left: 0;
    margin-left: 5px;
  }
`;

function Header() {
  return (
    <HeaderContainer>
      <BackButton
        icon={<ArrowLeftOutlined />}
        variant="link"
        aria-label="um link"
        id="button-id"
        href="/"
      >
        Voltar
      </BackButton>
      <img src={prevIcon} alt="icone prev" />
      <div style={{ width: "85px" }}></div>
    </HeaderContainer>
  );
}

export default Header;
