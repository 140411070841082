import {
  isChrome,
  isEdge,
  isFirefox,
  isIE,
  isSafari,
} from "react-device-detect";

const getDeviceType = () => {
  // https://documentation.onesignal.com/reference#add-a-device
  if (isIE || isEdge) {
    return 6;
  } else if (isFirefox) {
    return 8;
  } else if (isSafari) {
    return 7;
  } else if (isChrome) {
    return 5;
  }
  return 5;
};

const getCookie = (cname) => {
  var name = cname + "=";
  var decodedCookie = decodeURIComponent(document.cookie);
  var ca = decodedCookie.split(";");
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return null;
};

export const getSegmentObject = (info) => {
  const { usuario, assinatura, escritorio } = info;
  const cookie = getCookie("prev_session");

  let dados = {
    email: usuario.email,
    device_type: getDeviceType(),
    acesso: assinatura.acesso,
    status: assinatura.status,
    trial: assinatura.trial,
    plano: assinatura.intervalo,
    privilegio: usuario.privilegio,
    name: usuario.nome,
  };

  if (cookie) dados.prev_session = cookie;

  if (escritorio && escritorio.slug) {
    dados.slug = escritorio.slug;
  }

  return dados;
};
