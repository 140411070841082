import React, { useContext, useEffect, useState } from "react";
import { Tabs, SegmentContext } from "@prev/ui-kit";
import styled from "styled-components";
import Header from "../components/Header";
import EditarDados from "../components/EditarDados";
import MeuEscritorioComponent from "../components/MeuEscritorioComponent";
import DadosGeraisComponent from "../components/DadosGeraisComponent";
import { UserInfosContext } from "../context/UserInfosProvider";
import { getSegmentObject } from "../utils/Utils";

const ContainerRoot = styled.div`
  margin: 0 auto;
  max-width: 1280px;
  height: 100%;
  padding: 0 25px;
`;

const TabsStyled = styled(Tabs)`
  && {
    font-weight: 400;
    [aria-selected="true"] {
      font-weight: 600;
    }
  }
  .ant-tabs-nav-list {
    margin: 5px;
    padding-right: 5px;
  }
  @media (max-width: 819px) {
    .ant-tabs-nav-wrap {
      justify-content: center;
      margin-left: 0;
    }
  }
  @media (max-width: 479px) {
    .ant-tabs-nav-wrap {
      justify-content: flex-start;
    }
  }
`;

function MeuEscritorio() {
  const { userInfos } = useContext(UserInfosContext);
  const { analytics } = useContext(SegmentContext);
  const [disabledEditarDados, setDisabledEditarDados] = useState(true);

  useEffect(() => {
    const viewport_meta = document.getElementById("viewport-meta");
    if (viewport_meta) {
      viewport_meta.setAttribute("content", "width=device-width");
    }
  }, []);

  useEffect(() => {
    if (userInfos && userInfos.escritorio) {
      analytics?.identify(userInfos.usuario.uuid, getSegmentObject(userInfos));
      return setDisabledEditarDados(false);
    }
    setDisabledEditarDados(true);
  }, [userInfos]);

  useEffect(() => {
    if (analytics && userInfos) {
      analytics?.track("Entrou em Meu Diretório");
    }
  }, [analytics, userInfos]);

  return userInfos ? (
    <ContainerRoot>
      <Header />
      <TabsStyled
        defaultActiveKey="1"
        aria-label="Opções do escritório"
        onTabClick={(tab) => {
          const t = {
            1: "Estatísticas do meu escritório",
            2: "Estatísticas globais",
            3: "Editar Dados",
          };

          analytics?.track(`Diretório - entrou na aba ${t[tab]}`);
        }}
        components={[
          {
            title: "Estatísticas do meu escritório",
            key: "Estatísticas do meu escritório",
            value: (
              <MeuEscritorioComponent
                hasPerm={userInfos.assinatura.periodo.tiposDeModulo.includes(
                  "CATALOGO"
                )}
              />
            ),
            disabled: false,
          },
          {
            title: "Estatísticas globais",
            key: "Estatísticas globais",
            value: <DadosGeraisComponent hasPerm={true} />,
            disabled: false,
          },
          {
            title: "Editar Dados",
            key: "Editar Dados",
            value: <EditarDados />,
            disabled: disabledEditarDados,
          },
        ]}
        id="tabs-selecao"
        tabPosition="top"
      />
    </ContainerRoot>
  ) : null;
}

export default MeuEscritorio;
