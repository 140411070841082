import "mapbox-gl/dist/mapbox-gl.css";
import React, { forwardRef, useContext, useEffect, useState } from "react";
import {
  FullscreenControl,
  GeolocateControl,
  Layer,
  Map,
  NavigationControl,
  ScaleControl,
  Source,
} from "react-map-gl";
import { UserInfosContext } from "../context/UserInfosProvider";
import { MAPBOX_TOKEN } from "../core/Constants";

/* const escritoriosVisita = {
  id: "visita",
  source: "trackingDataVisita",
  type: "circle",
  layout: {
    visibility: "visible",
  },
  paint: {
    "circle-radius": {
      base: 1.75,
      stops: [
        [1, 3],
        [10, 4],
        [14, 6],
      ],
    },
    "circle-color": "#1b77c5",
  },
};

const escritoriosMensagem = {
  id: "mensagem",
  source: "trackingDataMensagem",
  type: "circle",
  layout: {
    visibility: "visible",
  },
  paint: {
    "circle-color": "#f26526",
    "circle-radius": {
      base: 1.75,
      stops: [
        [1, 3],
        [10, 4],
        [14, 6],
      ],
    },
  },
};

const escritoriosTelefone = {
  id: "telefone",
  source: "trackingDataTelefone",
  type: "circle",
  layout: {
    visibility: "visible",
  },
  paint: {
    "circle-color": "#ec1051",
    "circle-radius": {
      base: 1.75,
      stops: [
        [1, 3],
        [10, 4],
        [14, 6],
      ],
    },
  },
}; */

const dadosEscritorioLayer = {
  type: "circle",
  source: "dadosEscritorio",
  layout: {
    visibility: "visible",
  },
  paint: {
    // Make circles larger as the user zooms from z12 to z22.
    "circle-radius": {
      base: 1.75,
      stops: [
        [1, 3],
        [10, 4],
        [14, 6],
      ],
    },
    // Color circles by ethnicity, using a `match` expression.
    "circle-color": [
      "match",
      ["get", "tipo"],
      "VISITA",
      "#1B77C5",
      "VISUALIZACAO_TELEFONE",
      "#ec1051",
      "ENVIO_MENSAGEM",
      "#f26526",
      /* other */ "#ccc",
    ],
  },
};

const dadosGeraisLayer = {
  "source-layer": "diretorio",
  type: "circle",
  source: "dadosGerais",
  layout: {
    visibility: "visible",
  },
  paint: {
    // Make circles larger as the user zooms from z12 to z22.
    "circle-radius": {
      base: 1.75,
      stops: [
        [1, 3],
        [10, 4],
        [14, 6],
      ],
    },
    // Color circles by ethnicity, using a `match` expression.
    "circle-color": [
      "match",
      ["get", "tipo"],
      "VISITA",
      "#1B77C5",
      "VISUALIZACAO_TELEFONE",
      "#ec1051",
      "ENVIO_MENSAGEM",
      "#f26526",
      /* other */ "#ccc",
    ],
  },
};

let resized = false;

const MapComponent = forwardRef(
  ({ data, type = "escritorio", ...rest }, ref) => {
    const [mapData, setMapData] = useState();

    window.onresize = () => {
      if (!resized) resized = true;
    };

    const { currentPosition, viewState, setViewState } =
      useContext(UserInfosContext);

    const timeoutResize = () => {
      setTimeout(() => {
        ref.current.getMap().resize();
      }, 250);
    };

    useEffect(() => {
      if (resized) {
        const tabs = document.querySelectorAll(".ant-tabs-tab");
        tabs[0].addEventListener("click", timeoutResize);

        tabs[1].addEventListener("click", timeoutResize);
      }
    }, [resized]);

    useEffect(() => {
      const hasZoom = ref?.current?.getZoom();

      if (currentPosition) {
        setViewState({
          latitude: currentPosition.lat,
          longitude: currentPosition.lng,
          zoom: hasZoom ? ref.current.getZoom() : 11,
          minZoom: 4,
          maxZoom: 12,
        });
      }
    }, [currentPosition]);

    useEffect(() => {
      let dados = {
        type: "FeatureCollection",
        features: [],
      };

      if (data) {
        data.forEach(({ latitude, longitude, ...rest }) => {
          dados.features.push({
            properties: { ...rest },
            geometry: {
              type: "Point",
              coordinates: [longitude, latitude],
            },
          });
        });

        setMapData(dados);

        /* const newDataMsg = {
          type: "FeatureCollection",
          features: [
            ...dados.filter(
              (item) => item.properties.tipo === "ENVIO_MENSAGEM"
            ),
          ],
        };

        const newDataTel = {
          type: "FeatureCollection",
          features: [
            ...dados.filter(
              (item) => item.properties.tipo === "VISUALIZACAO_TELEFONE"
            ),
          ],
        };

        const newDataVst = {
          type: "FeatureCollection",
          features: [
            ...dados.filter((item) => item.properties.tipo === "VISITA"),
          ],
        }; */

        /* setMapData({
          msg: newDataMsg,
          tel: newDataTel,
          vst: newDataVst,
        }); */
      }
    }, [data]);

    return (
      <Map
        ref={ref}
        {...viewState}
        onMove={(evt) => setViewState(evt.viewState)}
        style={{ width: "100%", height: "100%" }}
        mapStyle="mapbox://styles/mapbox/streets-v9"
        mapboxAccessToken={MAPBOX_TOKEN}
        {...rest}
      >
        <GeolocateControl position="top-left" />
        <FullscreenControl position="top-left" />
        <NavigationControl position="top-left" />

        {type === "geral" && (
          <>
            <Source
              id="dadosGerais"
              type="vector"
              url={`mapbox://renanno.diretorio_tracking${
                process.env.REACT_APP_PREV_ENV === "production"
                  ? "_production"
                  : "_homolog"
              }`}
            />

            <Layer
              id="visita"
              {...dadosGeraisLayer}
              filter={["==", "tipo", "VISITA"]}
            />
            <Layer
              id="mensagem"
              {...dadosGeraisLayer}
              filter={["==", "tipo", "ENVIO_MENSAGEM"]}
            />
            <Layer
              id="telefone"
              {...dadosGeraisLayer}
              filter={["==", "tipo", "VISUALIZACAO_TELEFONE"]}
            />
          </>
        )}

        {mapData && type === "escritorio" && (
          <>
            <Source type="geojson" data={mapData} id="dadosEscritorio" />

            <Layer
              id="visita"
              {...dadosEscritorioLayer}
              filter={["==", "tipo", "VISITA"]}
            />
            <Layer
              id="mensagem"
              {...dadosEscritorioLayer}
              filter={["==", "tipo", "ENVIO_MENSAGEM"]}
            />
            <Layer
              id="telefone"
              {...dadosEscritorioLayer}
              filter={["==", "tipo", "VISUALIZACAO_TELEFONE"]}
            />
            {/* <Source
              type="geojson"
              data={mapData["vst"]}
              id="trackingDataVisita"
            />

            <Source
              type="geojson"
              data={mapData["msg"]}
              id="trackingDataMensagem"
            />

            <Source
              type="geojson"
              data={mapData["tel"]}
              id="trackingDataTelefone"
            /> */}

            {/* <Layer {...escritoriosVisita} />
            <Layer {...escritoriosTelefone} />
            <Layer {...escritoriosMensagem} /> */}
          </>
        )}
        <ScaleControl />
      </Map>
    );
  }
);
export default MapComponent;
