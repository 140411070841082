import React, { useContext, useEffect, useRef, useState } from "react";
import { Card, DatePicker, Tabs } from "@prev/ui-kit";
import styled from "styled-components";
import { Col, Row } from "antd";
import {
  MessageOutlined,
  PhoneOutlined,
  UserOutlined,
} from "@ant-design/icons";
import moment from "moment";
import InfoCard from "./InfoCard";
import MapComponent from "./MapComponent";
import { UserInfosContext } from "../context/UserInfosProvider";
import Chart, { getAllDatesBetween } from "./Chart";
import Modulescard from "./ModulesCard";
import MasterPlan from "./Plano";
import { FILTROS_DATERANGE } from "../core/Constants";

const ColStyledToDatePicker = styled(Col)`
  && {
    display: flex;
    justify-content: flex-end;
  }
  @media (max-width: 819px) {
    && {
      justify-content: center;
      margin-left: 4px;
    }
  }
`;

const DatePickerStyled = styled(DatePicker)`
  && {
    margin-top: -60px;
    margin-right: 5px;
    width: fit-content;
  }
  @media (max-width: 819px) {
    && {
      margin-top: 0;
      justify-content: center;
      margin-left: 5px;
    }
    .ant-picker-panel-container .ant-picker-panels {
      flex-direction: column;
    }
  }
  @media (max-width: 479px) {
    && {
      width: 100%;
    }
  }
`;

function DadosGeraisComponent({ hasPerm }) {
  const mapRef = useRef();

  const { getTracking, currentPosition } = useContext(UserInfosContext);

  const [infoCardVisits, setInfoCardVisits] = useState(0);
  const [infoCardMessages, setInfoCardMessages] = useState(0);
  const [infoCardPhone, setInfoCardPhone] = useState(0);
  const [hiddenModules, setHiddenModules] = useState([]);
  const [selectedTab, setSelectedTab] = useState("2");

  const [diasPassados, setDiasPassados] = useState();
  const [dateRange, setDateRange] = useState();
  const [dataGraphic, setDataGraphic] = useState();
  const [click, setClick] = useState({
    visitas: false,
    envios: false,
    cliques: false,
  });

  function numberFormatter(num) {
    let formatter = Intl.NumberFormat("en", { notation: "compact" });
    return formatter.format(num);
  }

  // Ao entrar na página
  useEffect(() => {
    const dtInicio = moment(new Date())
      .subtract(30, "days")
      .startOf("day")
      .format("YYYY-MM-DD HH:mm:ss");
    const dtFim = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");

    //Seta as datas de inicio e fim
    setDateRange({ inicio: dtInicio, fim: dtFim, agrupadoPor: "DIA" });
  }, []);

  useEffect(() => {
    if (
      document
        .querySelector(".datepicker-range")
        ?.childNodes[0].firstChild.hasAttribute("readonly")
    ) {
      const datepickerRange = document.querySelector(".datepicker-range");

      datepickerRange.childNodes[0].firstChild.removeAttribute("readonly");
      datepickerRange.childNodes[2].firstChild.removeAttribute("readonly");
      datepickerRange.childNodes[0].firstChild.setAttribute(
        "data-testid",
        "datepicker-data-incial"
      );
      datepickerRange.childNodes[2].firstChild.setAttribute(
        "data-testid",
        "datepicker-data-final"
      );
    }
  }, [dateRange]);

  useEffect(() => {
    if (!hasPerm) return;

    if (dateRange) {
      getUserTracking({
        de: dateRange.inicio,
        ate: dateRange.fim,
        agrupadoPor: dateRange.agrupadoPor,
        isGeral: true,
      });
    }
  }, [dateRange]);

  /**
   * Rastreia conforme os dateParams
   * @param {*} dateParams
   */
  const getUserTracking = (dateParams) => {
    if (!hasPerm) return;
    //nr de dias entre uma data e outra
    const dtDiff = moment(dateParams.ate).diff(dateParams.de, "days");

    getTracking(dateParams).then((dateResults) => {
      /* Realiza a contagem de items por tipo e soma para mostrar no card */
      const total = dateResults.reduce(
        (acumulador, item) => {
          acumulador[item.tipo] += item.quantidade;
          return acumulador;
        },
        { ENVIO_MENSAGEM: 0, VISUALIZACAO_TELEFONE: 0, VISITA: 0 }
      );

      setDiasPassados(dtDiff);

      setInfoCardMessages(numberFormatter(total["ENVIO_MENSAGEM"]));
      setInfoCardPhone(numberFormatter(total["VISUALIZACAO_TELEFONE"]));
      setInfoCardVisits(numberFormatter(total["VISITA"]));

      //Gera os dados para o gráfico
      setDataGraphic(dateResults);
    });
  };

  function updateMapDateFilter(dateRange) {
    if (!hasPerm) return;

    if (dateRange) {
      setDateRange({
        inicio: dateRange[0].startOf("day").format("YYYY-MM-DD HH:mm:ss"),
        fim: dateRange[1].endOf("day").format("YYYY-MM-DD HH:mm:ss"),
        agrupadoPor:
          getAllDatesBetween(
            dateRange[0].startOf("day").format("YYYY-MM-DD HH:mm:ss"),
            dateRange[1].endOf("day").format("YYYY-MM-DD HH:mm:ss")
          ).length > 31
            ? "MES"
            : "DIA",
      });
    }
  }

  const handleClick = (targetCard, _typeOperation) => {
    !hiddenModules.includes(targetCard)
      ? setHiddenModules([...hiddenModules, targetCard])
      : setHiddenModules(hiddenModules.filter((e) => e !== targetCard));

    if (mapRef) {
      const toggle = mapRef.current
        .getMap()
        .getLayoutProperty(`${targetCard}`, "visibility");

      mapRef.current
        .getMap()
        .setLayoutProperty(
          `${targetCard}`,
          "visibility",
          toggle === "visible" ? "none" : "visible"
        );
    }
  };

  return (
    <>
      <Row gutter={[16, 16]}>
        <ColStyledToDatePicker span={24}>
          {dateRange && (
            <DatePickerStyled
              range
              prefix="/"
              style={{
                display: "flex",
                justifyContent: "baseline",
                height: "fit-content",
              }}
              disabledDate={(current) =>
                current > moment(new Date()).endOf(`day`) ||
                current < moment(new Date()).subtract(1, "year")
              }
              value={[moment(dateRange.inicio), moment(dateRange.fim)]}
              defaultValue={[moment(dateRange.inicio), moment(dateRange.fim)]}
              onChange={(range) => {
                updateMapDateFilter(range);
                setSelectedTab("7");
              }}
              allowClear={false}
              placement="bottomRight"
              className="datepicker-range"
              data-testid="datepicker-input-dados-gerais"
            />
          )}
        </ColStyledToDatePicker>

        <Col md={8} lg={6} xs={24}>
          <Row gutter={[16, 16]}>
            {hasPerm ? (
              <>
                <Col xs={24}>
                  <Modulescard
                    Icon={UserOutlined}
                    titleCard="Visitas"
                    amount={infoCardVisits || 0}
                    dateRange={`Últimos ${diasPassados || 0} dias`}
                    textColor={"#1B77C5"}
                    iconBackgroundColor={"rgba(0, 129, 246, 0.12)"}
                    whichIcon="visita"
                    click={click}
                    setClick={setClick}
                    testid="visitas"
                    handleClick={handleClick}
                  />
                </Col>

                <Col xs={24}>
                  <Modulescard
                    Icon={MessageOutlined}
                    titleCard="Envio de mensagens"
                    amount={infoCardMessages || 0}
                    dateRange={`Últimos ${diasPassados || 0} dias`}
                    textColor={"#F26526"}
                    iconBackgroundColor={"#FEF0E9"}
                    whichIcon="mensagem"
                    click={click}
                    setClick={setClick}
                    testid="envio_mensagens"
                    handleClick={handleClick}
                  />
                </Col>

                <Col xs={24}>
                  <Modulescard
                    Icon={PhoneOutlined}
                    titleCard="Cliques no telefone"
                    amount={infoCardPhone || 0}
                    dateRange={`Últimos ${diasPassados || 0} dias`}
                    textColor={"#ec1051"}
                    iconBackgroundColor={"#f5ebee"}
                    rotate={90}
                    whichIcon="telefone"
                    click={click}
                    testid="visualizacao_telefone"
                    setClick={setClick}
                    handleClick={handleClick}
                  />
                </Col>
              </>
            ) : (
              <Col xs={24}>
                <MasterPlan height={371} margin={0} />
              </Col>
            )}

            <Col xs={0} md={24}>
              <InfoCard style={{ height: 340 }} />
            </Col>
          </Row>
        </Col>
        <Col md={16} lg={18} xs={24}>
          <Card
            style={{
              minHeight: 300,
              height: `100%`,
              padding: 0,
              margin: `0px 4px`,
              align: "right",
            }}
          >
            {currentPosition && <MapComponent type="geral" ref={mapRef} />}
          </Card>
        </Col>

        <Col xs={24}>
          <Tabs
            onTabClick={(tab) => {
              setDateRange(FILTROS_DATERANGE[tab - 1].params);
              setSelectedTab(tab);
            }}
            components={FILTROS_DATERANGE.map(({ title, disabled }) => ({
              title,
              disabled,
            }))}
            activeKey={selectedTab}
          />
          {hasPerm ? (
            <Chart
              data={dataGraphic}
              hiddenModules={hiddenModules}
              dateRange={dateRange}
              setDateRange={setDateRange}
              setSelectedTab={setSelectedTab}
            />
          ) : (
            <MasterPlan height={250} margin="0 2px 100px 2px" />
          )}
        </Col>
      </Row>
    </>
  );
}

export default DadosGeraisComponent;
