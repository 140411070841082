import {
  authenticatedGet,
  authenticatedPut,
  authenticatedPatch,
  authenticatedDelete,
} from "./adapter";

export const getUserInfo = async () => {
  return await authenticatedGet({ path: "dashboard/v2/info" }).then(
    (resp) => resp
  );
};

export const getUserTracking = async (params) => {
  const queryParams = new URLSearchParams(params);

  const url = `dashboard/v2/relatorios/catalogo/tracking${queryParams ? `?${queryParams}` : ``}`;
  return await authenticatedGet({ path: url }).then((resp) => resp);
};

export const getTrackingGeolocalization = async (
  de,
  ate,
  agrupadoPor,
  distancia,
  latitude,
  longitude
) => {
  const params = new URLSearchParams({
    de,
    ate,
    agrupadoPor,
    distancia,
    latitude,
    longitude,
  });
  const url = `dashboard/v2/relatorios/catalogo/geolocalizacao?${params}`;
  return await authenticatedGet({ path: url })
    .then((resp) => resp)
    .catch((_error) => ({
      error: "erro interno",
    }));
};

export const getCidadesCompetencia = async (value) => {
  return await authenticatedGet({
    path: `v1/cidades?nome=${value}`,
  })
    .then((resp) => resp)
    .catch((_error) => ({
      error: "erro interno",
    }));
};

export const getAreasAtuacao = async () => {
  return await authenticatedGet({
    path: `v1/especialidades`,
  })
    .then((resp) => resp)
    .catch((_error) => ({
      error: "erro interno",
    }));
};

export const putEscritorioAtualizado = async (data) => {
  return await authenticatedPut({
    path: `v1/escritorios/info`,
    data,
  })
    .then((resp) => resp)
    .catch((_error) => ({
      error: "erro interno",
    }));
};

export const patchImagemAtualizada = async (data) => {
  return await authenticatedPatch({
    path: "v1/escritorios/imagem-escritorio",
    data,
  })
    .then((resp) => resp)
    .catch((_error) => ({
      error: "erro interno",
    }));
};

export const deleteLogo = async (type) => {
  return await authenticatedDelete({
    path: `v1/escritorios/imagem-escritorio/${type.toUpperCase()}`,
  })
    .then((resp) => resp)
    .catch((resp) => ({ error: resp }));
};
