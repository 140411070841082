import { patchImagemAtualizada } from "../services/userService";

const uploadLogo = async (image) => {
  const formData = new FormData();
  formData.append("imagemExibicao", image, "imagemXXXXXImpressao.png");
  const response = await patchImagemAtualizada(formData);
  return response;
};

export default uploadLogo;
