import { Text, Tag, PrevPlatformContext } from "@prev/ui-kit";
import { EnvironmentOutlined } from "@ant-design/icons";
import React, { useContext } from "react";
import styled from "styled-components";
import rightHero from "../../images/backgroundLeft.png";
import leftHero from "../../images/backgroundRight.png";
import defaultProfileImage from "../../images/defaultProfileImg.png";

const HeroContainer = styled.div`
  height: 312px;
  background-image: ${`url(${leftHero}), url(${rightHero})`};
  background-position: right, left;
  background-size: 43%;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
`;

const InfoContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 350px;
  height: 100%;
  flex-direction: column;
`;

const ProfileImage = styled.img`
  width: 104px;
  height: 104px;
  border-radius: 50%;
  margin-bottom: 12px;
`;

const LocalizationContainer = styled.div`
  display: flex;
  align-items: center;
`;

function HeroCatalogo({ formInfo }) {
  const { theme } = useContext(PrevPlatformContext);
  const {
    numeroDaOAB = "",
    urlImagemEscritorioExibicao = "",
    uf = "",
    cidade = "",
    logradouro = "",
    numero = "",
    nome = "",
    especialidades = [],
  } = formInfo;

  return (
    <HeroContainer>
      <InfoContainer>
        <ProfileImage
          // src="https://thispersondoesnotexist.com/image"
          src={
            urlImagemEscritorioExibicao.base64
              ? urlImagemEscritorioExibicao.base64
              : urlImagemEscritorioExibicao
          }
          alt="Profile Picture"
          onError={(event) => {
            event.target.src = defaultProfileImage;
            event.onerror = null;
          }}
        />
        <Text
          style={{ marginBottom: 8 }}
          color={theme.color.brand.secondary}
          size="lg"
          emphasys
        >
          {nome}
        </Text>
        <Text
          style={{ marginBottom: 8 }}
          color={theme.color.neutral[1000]}
          emphasys
        >
          {especialidades[0] && especialidades[0].label
            ? especialidades[0].label
            : ""}
        </Text>
        <LocalizationContainer style={{ marginBottom: 16 }}>
          <EnvironmentOutlined
            style={{ color: theme.color.brand.secondary, marginRight: 9 }}
          />
          <Text color={theme.color.neutral[600]}>
            {logradouro} - {numero} - {cidade} - {uf}
          </Text>
        </LocalizationContainer>
        <Tag
          style={{ borderRadius: 100, padding: "2px 10px" }}
          type="secondary"
        >
          {numeroDaOAB}
        </Tag>
      </InfoContainer>
    </HeroContainer>
  );
}

export default HeroCatalogo;
