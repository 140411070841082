import axios from "axios";

async function getEnderecoByCep(cep) {
  try {
    const response = await axios.get(
      `https://api.homolog.prev.app/v1/utils/cep/${cep}`
    );
    return response.data;
  } catch (error) {
    console.error(error.response.data.error_description);
  }
}

export default getEnderecoByCep;
