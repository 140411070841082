import { Button } from "@prev/ui-kit";
import { Row, Col } from "antd";
import React from "react";
import styled from "styled-components";
import prevLogo from "../../images/previdenciaristaLogo.png";
import { MenuOutlined } from "@ant-design/icons";

const HeaderContainer = styled.div`
  height: 80px;
  background-color: ${({ theme }) => theme.color.brand.secondary};
  padding: 0 16px;
`;

const HeaderContainerMobile = styled.div`
  height: 48px;
  background-color: ${({ theme }) => theme.color.brand.secondary};
  padding: 0 16px;
`;

const RightContent = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  gap: 0 32px;
`;

const RightContentMobile = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  & .anticon {
    color: white;
    font-size: 28px;
  }
`;

function HeaderCatalogo() {
  return (
    <Row>
      <Col xs={0} sm={24}>
        <HeaderContainer>
          <Row
            style={{ height: "100%" }}
            align="middle"
            justify="space-between"
          >
            <Col span={8}>
              <img src={prevLogo} alt="Logo Previdenciarista" />
            </Col>
            <Col span={16}>
              <RightContent>
                <Button
                  aria-label="Soluções para aposentados"
                  id="btn-solucao-aposentados"
                  data-testid="btn-solucao-aposentados"
                  variant="link"
                  textColor="white"
                >
                  SOLUÇÕES PARA APOSENTADOS
                </Button>
                <Button
                  aria-label="Soluções para advogados"
                  id="btn-solucao-advogados"
                  data-testid="btn-solucao-advogados"
                  variant="link"
                  textColor="white"
                >
                  SOLUÇÕES PARA ADVOGADOS
                </Button>
              </RightContent>
            </Col>
          </Row>
        </HeaderContainer>
      </Col>
      <Col xs={24} sm={0}>
        <HeaderContainerMobile>
          <Row
            style={{ height: "100%" }}
            align="middle"
            justify="space-between"
          >
            <Col span={12}>
              <img src={prevLogo} alt="Logo Previdenciarista" />
            </Col>
            <Col span={12}>
              <RightContentMobile>
                <MenuOutlined />
              </RightContentMobile>
            </Col>
          </Row>
        </HeaderContainerMobile>
      </Col>
    </Row>
  );
}

export default HeaderCatalogo;
