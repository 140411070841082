import { Card } from "@prev/ui-kit";
import moment from "moment";
import React, { useEffect, useState } from "react";
import {
  Area,
  AreaChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
} from "recharts";
import { MESES } from "../core/Constants";

export const getAllDatesBetween = (
  startDate,
  endDate,
  agrupadoPor = "DIA",
  period
) => {
  const dates = [];
  const start = new Date(startDate);
  const end = new Date(endDate);
  while (start <= end) {
    dates.push({
      key: moment(new Date(start)).format(period),
      anoRef: start.getFullYear(),
    });
    if (agrupadoPor === "DIA") start.setDate(start.getDate() + 1);
    if (agrupadoPor === "MES") start.setMonth(start.getMonth() + 1);
    if (agrupadoPor === "HORA") start.setHours(start.getHours() + 1);
  }
  return dates;
};

const transformData = (data, dataRange) => {
  const periods = {
    DIA: "DD/MM",
    MES: "MM",
    HORA: "HH",
  };
  let datasFaltantes = getAllDatesBetween(
    dataRange.inicio,
    dataRange.fim,
    dataRange.agrupadoPor,
    periods[dataRange.agrupadoPor]
  );

  const agrupadoPor = data.reduce((agrupador, item) => {
    const year = new Date(item.data).getFullYear();
    const key = moment(item.data).format(periods[dataRange.agrupadoPor]);
    const grupo = agrupador.find((k) => k.data === key);
    if (!grupo) {
      agrupador.push({
        data: key,
        ENVIO_MENSAGEM: 0,
        VISITA: 0,
        VISUALIZACAO_TELEFONE: 0,
        anoRef: year,
        [item.tipo]: item.quantidade,
      });
    } else {
      if (!grupo[item.tipo]) {
        grupo[item.tipo] = item.quantidade;
      } else {
        grupo[item.tipo] += item.quantidade;
      }
    }

    return agrupador;
  }, []);

  datasFaltantes.forEach((data, index) => {
    const exists = agrupadoPor.find(
      (item) => item.data === data.key && item.anoRef === data.anoRef
    );
    if (exists) return (datasFaltantes[index] = exists);
    datasFaltantes[index] = {
      data: data.key,
      ENVIO_MENSAGEM: 0,
      VISITA: 0,
      VISUALIZACAO_TELEFONE: 0,
    };
  });

  if (dataRange.agrupadoPor === "MES")
    return datasFaltantes.map((mes) => ({
      ...mes,
      data: MESES[Number(mes.data) - 1],
    }));

  return datasFaltantes.map((diaOuHora) => ({
    ...diaOuHora,
    data:
      dataRange.agrupadoPor === "HORA"
        ? `${diaOuHora.data}:00`
        : diaOuHora.data,
  }));
};

const Chart = ({
  data,
  hiddenModules,
  dateRange,
  setDateRange,
  setSelectedTab,
}) => {
  const [graficoData, setGraficoData] = useState();
  useEffect(() => {
    if (data) {
      setGraficoData(transformData(data, dateRange));
    }
  }, [data, dateRange]);

  const handleChartClick = (data) => {
    if (dateRange.agrupadoPor === "MES") {
      const ano = new Date().getFullYear();
      const inicio = moment()
        .set({ year: ano, month: MESES.indexOf(data.activeLabel) })
        .startOf("month")
        .format("YYYY-MM-DD HH:mm:ss");
      const fim = moment()
        .set({ year: ano, month: MESES.indexOf(data.activeLabel) })
        .endOf("month")
        .format("YYYY-MM-DD HH:mm:ss");
      setDateRange({
        inicio: inicio,
        fim: fim,
        agrupadoPor: "DIA",
      });
      return setSelectedTab("7");
    }

    if (dateRange.agrupadoPor === "DIA") {
      const [dia, mes] = data.activeLabel.split("/");
      const ano = new Date(dateRange.inicio).getFullYear();
      const inicio = moment()
        .set({ year: ano, month: Number(mes) - 1, date: Number(dia) })
        .startOf("day")
        .format("YYYY-MM-DD HH:mm:ss");
      const fim = moment()
        .set({ year: ano, month: Number(mes) - 1, date: Number(dia) })
        .endOf("day")
        .format("YYYY-MM-DD HH:mm:ss");
      setDateRange({
        inicio: inicio,
        fim: fim,
        agrupadoPor: "HORA",
      });
      return setSelectedTab("7");
    }
  };

  return (
    <Card
      style={{
        height: 250,
        paddingBottom: 0,
        margin: "0 2px 100px 2px",
      }}
    >
      <ResponsiveContainer height={"100%"}>
        <AreaChart
          data={graficoData}
          onClick={(event) => handleChartClick(event)}
          stackOffset={1000}
        >
          <defs>
            <linearGradient id="colorVisita" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#1B77C5" stopOpacity={0.8} />
              <stop offset="95%" stopColor="#1B77C5" stopOpacity={0} />
            </linearGradient>
            <linearGradient id="colorMensagens" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#F26526" stopOpacity={0.8} />
              <stop offset="95%" stopColor="#F26526" stopOpacity={0} />
            </linearGradient>

            <linearGradient id="colorTelefone" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#ec1051" stopOpacity={0.8} />
              <stop offset="95%" stopColor="#ec1051" stopOpacity={0} />
            </linearGradient>
          </defs>
          <Tooltip />
          <CartesianGrid
            strokeDasharray="0"
            horizontal={false}
            stroke="#DDE5F0"
          />
          <XAxis
            dataKey="data"
            interval="preserveStartEnd"
            tickLine={false}
            allowDataOverflow
            strokeWidth={0}
            tick={{
              fontSize: 14,
              textTransform: "uppercase",
              fontWeight: "bold",
            }}
          />
          <Area
            type="monotone"
            dataKey="VISITA"
            stroke="#1B77C5"
            fillOpacity={1}
            fill="url(#colorVisita)"
            hide={hiddenModules.includes("visita")}
            name="Visitas recebidas"
            cursor={
              dateRange && dateRange.agrupadoPor !== "HORA"
                ? "pointer"
                : "default"
            }
          />

          <Area
            type="monotone"
            dataKey="ENVIO_MENSAGEM"
            stroke="#F26526"
            fillOpacity={1}
            fill="url(#colorMensagens)"
            hide={hiddenModules.includes("mensagem")}
            name="Mensagens enviadas"
            cursor={
              dateRange && dateRange.agrupadoPor !== "HORA"
                ? "pointer"
                : "default"
            }
          />

          <Area
            type="monotone"
            dataKey="VISUALIZACAO_TELEFONE"
            stroke="#ec1051"
            fillOpacity={1}
            fill="url(#colorTelefone)"
            hide={hiddenModules.includes("telefone")}
            name="Telefone visualizado"
            cursor={
              dateRange && dateRange.agrupadoPor !== "HORA"
                ? "pointer"
                : "default"
            }
          />
        </AreaChart>
      </ResponsiveContainer>
    </Card>
  );
};

export default Chart;
