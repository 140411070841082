import React, { useContext, useEffect, useState } from "react";
import { SaveOutlined, EyeOutlined, LoadingOutlined } from "@ant-design/icons";
import { Form, Row, Col, notification } from "antd";
import styled from "styled-components";
import { debounce } from "lodash";
import {
  FieldInput,
  Text,
  Select,
  TextArea,
  Label,
  Button,
  Switch,
  UploadImage,
  SegmentContext,
} from "@prev/ui-kit";
import { UserInfosContext } from "../context/UserInfosProvider";
import infoPrevImage from "../images/prevInfoImage.png";
import getEnderecoByCep from "../functions/getEnderecoByCep";
import {
  deleteLogo,
  getCidadesCompetencia,
  putEscritorioAtualizado,
} from "../services/userService";
import DrawerOpen from "./Catalogo/DrawerOpen";
import uploadLogo from "../functions/uploadLogo";

const ContainerFlex = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 25px;
`;

const SwitchContainer = styled.div`
  width: 70px;
`;

const ContainerInfoBox = styled.div`
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.07);
  border-radius: 4px;
  padding: 30px;
  width: calc(100% - 5px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const ImageInfo = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

function EditarDados() {
  const { analytics } = useContext(SegmentContext);
  const { userInfos, areasAtuacao } = useContext(UserInfosContext);
  const {
    escritorio: {
      // competenciaEstadual,
      // competenciaFederal,
      cidadesDeAtuacao,
      nome,
      slug,
      cnpj,
      numeroDeFuncionarios,
      urlImagemEscritorioExibicao,
      numeroDaOab,
      telefone,
      telefoneSecundario,
      email,
      endereco,
      especialidades,
      descricao,
    },
  } = userInfos;

  const [form] = Form.useForm();
  const [cepInfos, setCepInfos] = useState({});
  const [formCepDisable, setFormCepDisable] = useState({
    uf: true,
    cidade: true,
    bairro: true,
    endereco: true,
  });
  const [showDrawer, setShowDrawer] = useState(false);
  const [loadingSelect, setLoadingSelect] = useState(false);
  const [disableSendFormButton, setDisableSendFormButton] = useState(false);
  const [ativoCatalogo, setAtivoCatalogo] = useState(
    userInfos.escritorio.ativoCatalogo
  );

  const cidadesPreDefinidas = () => {
    const todasCidadesDoUsuario = [];

    if (cidadesDeAtuacao) {
      todasCidadesDoUsuario.push(
        ...cidadesDeAtuacao.map((cidade) => ({
          label: cidade.descricao,
          value: cidade.id,
        }))
      );
    }

    // if (competenciaEstadual)
    //   todasCidadesDoUsuario.push({
    //     label: competenciaEstadual.descricao,
    //     value: competenciaEstadual.id,
    //   });

    // if (competenciaFederal)
    //   todasCidadesDoUsuario.push({
    //     label: competenciaFederal.descricao,
    //     value: competenciaFederal.id,
    //   });

    // https://pt.stackoverflow.com/questions/296660/como-remover-valores-duplicados-de-um-array-de-objetos-facilmente

    return todasCidadesDoUsuario.filter(function (a) {
      return !this[JSON.stringify(a)] && (this[JSON.stringify(a)] = true);
    }, Object.create(null));
  };

  const [fetchCidadeDeAtuacao, setFetchCidadeDeAtuacao] = useState(
    cidadesPreDefinidas()
  );

  const onFinish = async (values) => {
    analytics?.track("Diretório - Salvou as alterações no Editar Dados");
    setDisableSendFormButton(true);
    const existCep = values.cep
      ? {
          endereco: {
            cep: values.cep,
            endereco: values.logradouro,
            cidade: values.cidade,
            complemento: values.complemento,
            uf: values.uf,
            bairro: values.bairro,
            numero: values.numero,
          },
        }
      : {};

    const formatForm = {
      ...values,
      ...existCep,
      nome: values.nome,
      slug,
      cidadesDeAtuacaoIds: values.cidadesDeAtuacao.map(
        (cidade) => cidade.value
      ),
      especialidadesIds: values.especialidades.map(
        (especialidade) => especialidade.value
      ),
      ativoCatalogo,
      descricao:
        typeof values.descricao === "string"
          ? values.descricao.replaceAll(
              /\(?[0-9]{2}\)? ?9?[0-9]{4}[ \-]?-?[0-9]{4}/g,
              "************"
            )
          : undefined,
      // competenciaEstadualId: values.competenciaEstadual,
      // competenciaFederalId: values.competenciaFederal,
    };

    if (
      values.urlImagemEscritorioExibicao &&
      values.urlImagemEscritorioExibicao.file
    ) {
      const responseImage = await uploadLogo(
        values.urlImagemEscritorioExibicao.file
      );
      if (responseImage.error) {
        notification.error({
          placement: "top",
          message: "Erro ao alterar a logo",
        });
      }
    }

    if (
      values.urlImagemEscritorioExibicao !== urlImagemEscritorioExibicao &&
      !values.urlImagemEscritorioExibicao
    ) {
      const { error } = await deleteLogo("EXIBICAO");
      if (error) {
        notification.error({
          placement: "top",
          message: "Erro ao deletar a logo",
        });
      }
    }

    const response = await putEscritorioAtualizado(formatForm);
    if (response.error) {
      notification.error({
        placement: "top",
        message: "Erro ao atualizar os dados",
      });
      return setDisableSendFormButton(false);
    }
    notification.success({
      placement: "top",
      message: "Dados atualizados com sucesso!",
    });
    return setDisableSendFormButton(false);
  };

  const onFinishFailed = (errorInfo) => {
    notification.error({
      placement: "top",
      message: "Por favor, inclua todos os campos obrigatórios",
    });
    console.log("Failed:", errorInfo);
  };

  const resolveCep = async (cep) => {
    const response = await getEnderecoByCep(cep);
    Object.keys(response).map((item) => {
      if (response[item]) {
        setCepInfos((prev) => ({ ...prev, [item]: response[item] }));
        return setFormCepDisable((prev) => ({ ...prev, [item]: true }));
      }
      return setFormCepDisable((prev) => {
        return { ...prev, [item]: false };
      });
    });
  };

  useEffect(() => {
    if (Object.values(cepInfos).length) {
      form.setFieldsValue({
        uf: cepInfos.uf,
        cidade: cepInfos.cidade,
        bairro: cepInfos.bairro,
        logradouro: cepInfos.endereco,
      });
    }
  }, [cepInfos, form]);

  const fetchCep = async ({ target: { value } }) => {
    if (value.length === 9) {
      form.setFieldsValue({ cep: value });
      resolveCep(value);
    } else {
      setFormCepDisable({
        logradouro: true,
        cidade: true,
        uf: true,
        bairro: true,
      });
      setCepInfos({ uf: "", cidade: "", bairro: "", endereco: "", cep: value });
      form.setFieldsValue({
        uf: "",
        cidade: "",
        bairro: "",
        logradouro: "",
        cep: value,
      });
    }
  };

  // https://www.carlrippon.com/using-lodash-debounce-with-react-and-ts/
  const debouncedSearch = React.useRef(
    debounce(async (value) => {
      const responses = await getCidadesCompetencia(value);
      const filtrados = responses.filter(
        (cidade) =>
          !cidadesPreDefinidas().some(
            (cidadeSome) => cidade.id === cidadeSome.value
          )
      );
      setFetchCidadeDeAtuacao([
        ...cidadesPreDefinidas(),
        ...filtrados.map((response) => {
          return {
            label: response.descricao,
            value: response.id,
          };
        }),
      ]);
      setLoadingSelect(false);
    }, 300)
  ).current;

  const resolveCidadeAtuacao = async (value) => {
    setLoadingSelect(true);
    debouncedSearch(value);
  };

  useEffect(() => {
    return () => {
      debouncedSearch.cancel();
    };
  }, [debouncedSearch]);

  return (
    <div>
      <ContainerFlex>
        <SwitchContainer>
          <Switch
            defaultChecked={ativoCatalogo}
            onChange={() => setAtivoCatalogo(!ativoCatalogo)}
            id="switch-ativar-catalogo"
            data-testid="switch-ativar-catalogo"
            aria-label="Exibe escritório no Diretório de Advogados"
          />
        </SwitchContainer>
        <Text>Quero exibir meu escritório no Diretório de Advogados</Text>
      </ContainerFlex>
      <Form
        name="userForm"
        form={form}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <div style={{ marginBottom: 60 }}>
          <Row gutter={[108, 0]}>
            <Col sm={24} md={24} lg={14}>
              <Row gutter={24}>
                <Col xs={24} md={12}>
                  <Form.Item
                    name="nome"
                    rules={[
                      {
                        required: true,
                        message: "Por favor insira um nome para seu escritório",
                        type: "string",
                      },
                    ]}
                    initialValue={nome}
                  >
                    <FieldInput
                      label={"Nome do escritório"}
                      placeholder={"Nome do escritório"}
                      aria-label="Nome do escritório"
                      id="escritorio-nome"
                      data-testid="escritorio-nome"
                    />
                  </Form.Item>
                </Col>
                {/* <Col xs={24} md={12}>
                  <Form.Item name="slug" initialValue={slug}>
                    <FieldInput
                      label={"Slug do escritório"}
                      placeholder={"Slug do escritório"}
                      aria-label="Slug do escritório"
                      id="escritorio-slug"
                      data-testid="escritorio-slug"
                      disabled
                    />
                  </Form.Item>
                </Col> */}
                <Col xs={24} md={12}>
                  <Form.Item
                    name="numeroDaOAB"
                    rules={[
                      {
                        required: ativoCatalogo,
                        message: "Por favor digite o número da OAB",
                        type: "string",
                        min: 13,
                      },
                    ]}
                    initialValue={numeroDaOab}
                  >
                    <FieldInput
                      mask="aaa/aa 999.999"
                      maskChar=""
                      label={"Número da OAB"}
                      placeholder={"OAB/XX 999.999"}
                      aria-label="Número da OAB"
                      id="escritorio-oab"
                      data-testid="escritorio-oab"
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} md={12}>
                  <Form.Item
                    name="cnpj"
                    rules={[
                      {
                        required: false,
                        message: "Por favor digite o CNPJ da empresa",
                        type: "string",
                        min: 11,
                      },
                    ]}
                    initialValue={cnpj}
                  >
                    <FieldInput
                      mask="99.999.999/9999-99"
                      maskChar=""
                      label={"CNPJ"}
                      placeholder={"999.999.999-99"}
                      aria-label="CNPJ"
                      id="escritorio-cnpj"
                      data-testid="escritorio-cnpj"
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} md={12}>
                  <Form.Item
                    name="numeroDeFuncionarios"
                    rules={[
                      {
                        required: false,
                        message:
                          "Por favor digite o número de funcionários da empresa",
                      },
                    ]}
                    initialValue={numeroDeFuncionarios}
                  >
                    <FieldInput
                      type="number"
                      label={"Número de funcionários"}
                      aria-label="Número de funcionários"
                      id="escritorio-number-funcionarios"
                      data-testid="escritorio-number-funcionarios"
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} md={24}>
                  <Row gutter={[24, 0]}>
                    <Col md={12} xs={24}>
                      <Label aria-label="logo.escritorio" id="logo.escritorio">
                        Logo da Plataforma
                      </Label>
                      <Form.Item
                        name="urlImagemEscritorioExibicao"
                        rules={[
                          {
                            required: ativoCatalogo,
                            message: "Por favor carregue uma imagem válida.",
                          },
                        ]}
                        initialValue={urlImagemEscritorioExibicao}
                      >
                        <UploadImage
                          previewSize={{ width: "150px", height: "200px" }}
                          style={{ marginTop: 8 }}
                          buttonSize="large"
                          aria-label="Logo da Plataforma"
                          id="escritorio-logo-escritorio"
                          data-testid="escritorio-logo-escritorio"
                          // previewSize={{ width: `200px`, height: `100px` }}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
                {/* <Col xs={24} md={12}>
                  <Form.Item
                    name="competenciaEstadual"
                    rules={[
                      {
                        required: ativoCatalogo,
                        message: "Por favor selecione uma competência Estadual",
                      },
                    ]}
                    initialValue={
                      competenciaEstadual && competenciaEstadual.id
                        ? competenciaEstadual.id
                        : undefined
                    }
                  >
                    <Select
                      loading={loadingSelect}
                      showSearch
                      label={"Competência Estadual"}
                      placeholder={"Cidade"}
                      aria-label="Competência Estadual"
                      id="escritorio.competencia.estadual"
                      data-testid="escritorio.competencia.estadual"
                      options={fetchCidadeDeAtuacao}
                      optionFilterProp="children"
                      onSearch={(inputValue) =>
                        resolveCidadeAtuacao(inputValue)
                      }
                      allowClear={true}
                    />
                  </Form.Item>
                </Col> */}
                {/* <Col xs={24} md={12}>
                  <Form.Item
                    name="competenciaFederal"
                    rules={[
                      {
                        required: ativoCatalogo,
                        message: "Por favor selecione uma competência Federal",
                      },
                    ]}
                    initialValue={
                      competenciaFederal && competenciaFederal.id
                        ? competenciaFederal.id
                        : undefined
                    }
                  >
                    <Select
                      loading={loadingSelect}
                      showSearch
                      label={"Competência Federal"}
                      placeholder={"Cidade"}
                      aria-label="Competência federal"
                      id="escritorio.competencia.federal"
                      data-testid="escritorio.competencia.federal"
                      options={fetchCidadeDeAtuacao}
                      optionFilterProp="children"
                      onSearch={(inputValue) =>
                        resolveCidadeAtuacao(inputValue)
                      }
                      allowClear={true}
                    />
                  </Form.Item>
                </Col> */}
                <Col xs={24} md={12}>
                  <Form.Item
                    name="telefone"
                    rules={[
                      {
                        required: ativoCatalogo,
                        message:
                          "Por favor insira um número de telefone válido",
                        type: "string",
                        min: 14,
                      },
                    ]}
                    initialValue={telefone}
                  >
                    <FieldInput
                      mask="(99) 99999-9999"
                      maskChar=""
                      label={"Telefone 1"}
                      placeholder={"DDD - 99999-9999"}
                      aria-label="Telefone 1"
                      id="escritorio-telefone-um"
                      data-testid="escritorio-telefone-um"
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} md={12}>
                  <Form.Item
                    name="telefoneSecundario"
                    rules={[
                      {
                        message:
                          "Por favor insira um número de telefone válido",
                        type: "string",
                        min: 14,
                      },
                    ]}
                    initialValue={telefoneSecundario}
                  >
                    <FieldInput
                      mask="(99) 99999-9999"
                      maskChar=""
                      label={"Telefone 2"}
                      placeholder={"DDD - 99999-9999"}
                      aria-label="Telefone 2"
                      id="escritorio-telefone-dois"
                      data-testid="escritorio-telefone-dois"
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} md={24}>
                  <Form.Item
                    name="email"
                    rules={[
                      {
                        type: "email",
                        message: "Por favor insira um email válido",
                      },
                    ]}
                    initialValue={email}
                  >
                    <FieldInput
                      label={"Email"}
                      placeholder={"Digite o email"}
                      aria-label="Email"
                      id="escritorio-email"
                      data-testid="escritorio-email"
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} md={12}>
                  <Form.Item
                    name="cep"
                    rules={[
                      {
                        required: ativoCatalogo,
                        message: "Por favor insira um CEP válido",
                        type: "string",
                        min: 9,
                      },
                    ]}
                    initialValue={
                      endereco && endereco.cep ? endereco.cep : undefined
                    }
                  >
                    <FieldInput
                      onChange={(e) => fetchCep(e)}
                      mask="99999-999"
                      maskChar=""
                      label={"CEP"}
                      placeholder={"99999-999"}
                      aria-label="CEP"
                      id="escritorio-cep"
                      data-testid="escritorio-cep"
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} md={12}>
                  <Form.Item
                    name="uf"
                    rules={[
                      {
                        required: ativoCatalogo,
                        message: "Por favor insira um estádo válido",
                      },
                    ]}
                    initialValue={
                      endereco && endereco.uf ? endereco.uf : undefined
                    }
                  >
                    <FieldInput
                      label={"Estado"}
                      placeholder={"Digite o estado"}
                      aria-label="Estado"
                      id="escritorio-estado"
                      data-testid="escritorio-estado"
                      disabled={formCepDisable.uf}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} md={12}>
                  <Form.Item
                    name="cidade"
                    rules={[
                      {
                        required: ativoCatalogo,
                        message: "Por favor insira uma cidade válida",
                      },
                    ]}
                    initialValue={
                      endereco && endereco.cidade ? endereco.cidade : undefined
                    }
                  >
                    <FieldInput
                      label={"Cidade"}
                      placeholder={"Digite a cidade"}
                      aria-label="Cidade"
                      id="escritorio-cidade"
                      data-testid="escritorio-cidade"
                      disabled={formCepDisable.cidade}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} md={12}>
                  <Form.Item
                    name="bairro"
                    rules={[
                      {
                        required: ativoCatalogo,
                        message: "Por favor insira um bairro válido",
                      },
                    ]}
                    initialValue={
                      endereco && endereco.bairro ? endereco.bairro : undefined
                    }
                  >
                    <FieldInput
                      label={"Bairro"}
                      placeholder={"Digite o bairro"}
                      aria-label="Bairro"
                      id="escritorio-bairro"
                      data-testid="escritorio-bairro"
                      disabled={formCepDisable.bairro}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} md={24}>
                  <Form.Item
                    name="logradouro"
                    rules={[
                      {
                        required: ativoCatalogo,
                        message: "Por favor insira uma rua válida",
                      },
                    ]}
                    initialValue={
                      endereco && endereco.logradouro
                        ? endereco.logradouro
                        : undefined
                    }
                  >
                    <FieldInput
                      label={"Rua"}
                      placeholder={"Digite a rua"}
                      aria-label="Rua"
                      id="escritorio-rua"
                      data-testid="escritorio-rua"
                      disabled={formCepDisable.endereco}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} md={12}>
                  <Form.Item
                    name="numero"
                    rules={[
                      {
                        required: ativoCatalogo,
                        message:
                          "Por favor insira um número válido de residência",
                      },
                    ]}
                    initialValue={
                      endereco && endereco.numero ? endereco.numero : undefined
                    }
                  >
                    <FieldInput
                      label={"Número"}
                      placeholder={"Digite o número"}
                      aria-label="Número"
                      id="escritorio-numero"
                      data-testid="escritorio-numero"
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} md={12}>
                  <Form.Item
                    name="complemento"
                    initialValue={
                      endereco && endereco.complemento
                        ? endereco.complemento
                        : undefined
                    }
                  >
                    <FieldInput
                      label={"Complemento"}
                      placeholder={"Digite o complemento"}
                      aria-label="Complemento"
                      id="escritorio-complemento"
                      data-testid="escritorio-complemento"
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} md={12}>
                  <Form.Item
                    name="cidadesDeAtuacao"
                    rules={[
                      {
                        required: ativoCatalogo,
                        message: "Por favor selecione ao menos uma cidade",
                      },
                    ]}
                    initialValue={fetchCidadeDeAtuacao}
                    onChange={(e) => resolveCidadeAtuacao(e.target.value)}
                  >
                    <Select
                      loading={loadingSelect}
                      label={"Cidades de Atuação"}
                      placeholder={"Selecione uma ou mais cidades"}
                      aria-label="Cidades de Atuação"
                      id="escritorio-cidade-de-atuacao"
                      data-testid="escritorio-cidade-de-atuacao"
                      options={fetchCidadeDeAtuacao}
                      mode="multiple"
                      onSearch={(inputValue) =>
                        resolveCidadeAtuacao(inputValue)
                      }
                      onChange={(_value, options) => {
                        form.setFieldsValue({
                          cidadesDeAtuacao: options.map(
                            ({ value, children }) => ({
                              label: children,
                              value,
                            })
                          ),
                        });
                      }}
                      allowClear={true}
                      optionFilterProp="children"
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} md={12}>
                  <Form.Item
                    name="especialidades"
                    rules={[
                      {
                        required: ativoCatalogo,
                        message:
                          "Por favor selecione uma ou mais área(s) de atuação",
                      },
                    ]}
                    initialValue={
                      especialidades
                        ? especialidades.map((especialidade) => ({
                            label: especialidade.descricao,
                            value: especialidade.id,
                          }))
                        : []
                    }
                  >
                    <Select
                      loading={loadingSelect}
                      label={"Áreas de atuação"}
                      placeholder={"Selecione uma ou mais área(s) de atuação"}
                      aria-label="Áreas de atuação"
                      id="escritorio-areas-de-atuacao"
                      data-testid="escritorio-areas-de-atuacao"
                      options={areasAtuacao.map((especialidade) => ({
                        label: especialidade.descricao,
                        value: especialidade.id,
                      }))}
                      mode="multiple"
                      onChange={(_value, options) => {
                        form.setFieldsValue({
                          especialidades: options.map(
                            ({ value, children }) => ({
                              label: children,
                              value,
                            })
                          ),
                        });
                      }}
                      allowClear={true}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} md={24}>
                  <Form.Item
                    name="descricao"
                    rules={[
                      {
                        required: ativoCatalogo,
                        message:
                          "Por favor insira uma descrição para o seu escritório",
                      },
                    ]}
                    initialValue={descricao}
                  >
                    <TextArea
                      label={"Descrição do escritório"}
                      placeholder="Conte um pouco mais sobre seu escritório neste campo..."
                      size="large"
                      aria-label="Descrição do escritório"
                      id="escritorio-descricao"
                      data-testid="escritorio-descricao"
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <Col style={{ marginTop: 30 }} xs={24} md={24} lg={10}>
              <Row gutter={[16, 16]}>
                <Col xs={24} md={24} xl={14}>
                  <Button
                    style={{
                      backgroundColor: "white",
                      marginRight: 15,
                      width: "100%",
                    }}
                    size="large"
                    icon={<EyeOutlined />}
                    color="#949595"
                    textColor="#949595"
                    onClick={() => {
                      setShowDrawer(true);
                      analytics?.track(
                        "Diretório - Abriu pré-visualização no Editar Dados"
                      );
                    }}
                    aria-label="Pré-visualização alterações"
                    id="btn-pre-visualizar-alteracoes"
                    data-testid="btn-pre-visualizar-alteracoes"
                  >
                    Pré-visualização alterações
                  </Button>
                </Col>
                <Col xs={24} md={24} xl={10}>
                  <Form.Item>
                    <Button
                      style={{ width: "100%" }}
                      size="large"
                      icon={
                        disableSendFormButton ? (
                          <LoadingOutlined />
                        ) : (
                          <SaveOutlined />
                        )
                      }
                      htmlType="submit"
                      aria-label="Salvar alterações"
                      id="btn-salvar-alteracoes"
                      data-testid="btn-salvar-alteracoes"
                      disabled={disableSendFormButton}
                    >
                      {disableSendFormButton
                        ? "Enviando..."
                        : "Salvar alterações"}
                    </Button>
                  </Form.Item>
                </Col>
                <Col xs={0} md={0} lg={24}>
                  <ContainerInfoBox
                    aria-label="Dica Previdenciarista"
                    id="card-dica"
                    data-testid="card-dica"
                  >
                    <ImageInfo>
                      <img
                        width="250px"
                        alt="garota buscando informações"
                        src={infoPrevImage}
                      />
                    </ImageInfo>
                    <Text>
                      <p>
                        Quanto mais dados você colocar, maior será o alcance do
                        seu escritório.
                      </p>
                      <p>
                        Hoje em dia as pessoas costumam buscar por advogados no
                        Google. Geralmente a pesquisa é feita com a palavra
                        "advogado" e a especialidade, por exemplo: "advogado
                        trabalhista", "advogado criminalista". Outra forma que
                        as pessoas buscam é: advogado + especialidade + cidade.
                        Por exemplo: "advogado inss em porto alegre"
                      </p>
                      <p>
                        <strong>
                          Uma das vantagens de assinar o Diretório de Advogados
                          do Prev é que nós sempre estaremos nas primeiras
                          posições do Google
                        </strong>{" "}
                        para resultados orgânicos, por sermos um site relevante
                        e com grande autoridade no assunto.
                      </p>
                      <p>
                        Uma das melhores formas de garantir uma boa posição
                        nesse caso, é <strong>ter o perfil completo</strong> e{" "}
                        <strong>escrever uma boa descrição</strong>.
                      </p>
                      <p>
                        Conte a história do seu escritório, desde quando você
                        atua, qual estrutura você tem.{" "}
                        <strong>
                          Use palavras simples, de fácil leitura e compreensão.
                        </strong>
                      </p>
                      <p>
                        Fale sobre as especializações que você tem ou sua
                        formação de um modo geral. Diga que é especialista em
                        Direito Previdenciário se for o caso, que se formou no
                        ano X e permanece se atualizando desde então.
                      </p>
                      <p>
                        A <strong>descrição do escritório</strong> é o espaço
                        que você tem para vender sua atividade. Apenas tenha
                        sempre atenção às normas do nosso{" "}
                        <a
                          href="https://www.oab.org.br/content/pdf/legislacaooab/codigodeetica.pdf"
                          target="_blank"
                          title="Código de Ética da OAB"
                        >
                          Código de Ética
                        </a>{" "}
                        e{" "}
                        <a
                          href="http://www.planalto.gov.br/ccivil_03/leis/l8906.htm"
                          target="_blank"
                          title="Estatuto da OAB"
                        >
                          Estatuto da OAB
                        </a>{" "}
                        para isso. É possível escrever uma boa descrição sem
                        violar nenhuma regra da Ordem.
                      </p>
                      <p>
                        Lembre de também de informar as{" "}
                        <strong>áreas de atuação</strong> e{" "}
                        <strong>cidades de atuação</strong>. Porém não informe
                        áreas e cidades onde você não atua para não prejudicar a
                        experiência do usuário, do cliente em potencial.
                      </p>
                    </Text>
                  </ContainerInfoBox>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </Form>

      {showDrawer && (
        <DrawerOpen
          setShowDrawer={setShowDrawer}
          showDrawer={showDrawer}
          formInfo={form.getFieldsValue()}
        />
      )}
    </div>
  );
}

export default EditarDados;
