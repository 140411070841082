import React, { createContext, useEffect, useState } from "react";
import {
  getTrackingGeolocalization,
  getUserInfo,
  getUserTracking,
  getAreasAtuacao,
  // getCidadesCompetencia,
} from "../services/userService";

export const UserInfosContext = createContext({});

function UserInfosProvider({ children }) {
  const [userInfos, setUserInfos] = useState();
  const [areasAtuacao, setAreasAtuacao] = useState([]);
  const [currentPosition, setCurrentPosition] = useState();
  const [viewState, setViewState] = useState({
    latitude: -14.588331552887231,
    longitude: -53.18055725097656,
    zoom: 4,
    minZoom: 4,
    maxZoom: 12,
  });

  useEffect(() => {
    getInfoUser();
    getAreasAtuacaoFetch();
  }, []);

  const getTracking = async (params) => {
    return getUserTracking(params);
  };

  const getLocation = async (
    de,
    ate,
    agrupadoPor,
    distancia,
    latitude,
    longitude
  ) => {
    return getTrackingGeolocalization(
      de,
      ate,
      agrupadoPor,
      distancia,
      latitude,
      longitude
    );
  };

  const getInfoUser = async () => {
    const result = await getUserInfo();
    setUserInfos(result);
  };

  const getAreasAtuacaoFetch = async () => {
    const result = await getAreasAtuacao();
    setAreasAtuacao(result);
  };

  useEffect(() => {
    async function selfCurrentLocation() {
      if (userInfos) {
        let crCoords = { lat: -14.588331552887231, lng: -53.18055725097656 };
        if (userInfos.escritorio && userInfos.escritorio.endereco) {
          if (
            userInfos.escritorio.endereco.latitude &&
            userInfos.escritorio.endereco.longitude
          ) {
            crCoords.lat = userInfos.escritorio.endereco.latitude;
            crCoords.lng = userInfos.escritorio.endereco.longitude;
          }
        } else if ("geolocation" in navigator) {
          navigator.geolocation.getCurrentPosition(
            (location) => {
              crCoords.lat = location.coords.latitude;
              crCoords.lng = location.coords.longitude;
              return setCurrentPosition({
                lat: location.coords.latitude,
                lng: location.coords.longitude,
              });
            },
            (err) => {
              setViewState({
                latitude: crCoords.lat,
                longitude: crCoords.lng,
                zoom: 4,
              });
            },
            {
              timeout: 50000,
            }
          );
        }
        setCurrentPosition(crCoords);
      }
    }

    selfCurrentLocation();
  }, [userInfos]);

  const context = {
    userInfos,
    areasAtuacao,
    currentPosition,
    setCurrentPosition,
    getTracking,
    getLocation,
    viewState,
    setViewState,
  };
  return (
    <UserInfosContext.Provider value={context}>
      {children}
    </UserInfosContext.Provider>
  );
}

export default UserInfosProvider;
