import React, { useContext } from "react";
import { Row, Col } from "antd";
import { Button, Text, Card, SegmentContext } from "@prev/ui-kit";
import IconCadeado from "../images/IconCadeado.svg";

function MasterPlan({ height, margin }) {
  const { analytics } = useContext(SegmentContext);
  return (
    <Card
      style={{
        width: "100%",
        height,
        background: "#00000080",
        display: "flex",
        alignItems: "center",
        margin,
      }}
    >
      <Row
        gutter={[0, 32]}
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Col span={24}>
          <img src={IconCadeado} alt="icone-cadeado" />
        </Col>

        <Col span={24}>
          <Text emphasys style={{ color: "#FFFFFF", textAlign: "center" }}>
            Assine o Diretório de Advogados do Prev para poder visualizar as
            estatísticas de visualizações do seu escritório.
          </Text>
        </Col>

        <Col span={24}>
          <Button
            aria-label="botao-assinar"
            id="button-id"
            onClick={() => {
              analytics?.track(
                "Diretório - Iniciou alteração de plano para adicionar módulo do diretório"
              );
              // Para o evento do segment acontecer (ou aparecer no network pelo menos)
              setTimeout(() => {
                window.location.href = "/plano/alterar?modulo=CATALOGO";
              }, 100);
            }}
            // href="/alterarPlano?modulo=CATALOGO"
          >
            Assinar o diretório agora
          </Button>
        </Col>
      </Row>
    </Card>
  );
}

export default MasterPlan;
